"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeExtraPay = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayChanged_1 = require("../../../events/company/extrapay/ExtraPayChanged");
var ChangeExtraPay = function (_CommandRoot_1$Comman) {
  function ChangeExtraPay(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeExtraPay);
    _this = _callSuper(this, ChangeExtraPay, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeExtraPay';
    return _this;
  }
  (0, _inherits2.default)(ChangeExtraPay, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeExtraPay, [{
    key: "getExtraPay",
    value: function () {
      var _getExtraPay = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPay == null) this._extraPay = yield CommandReadModels_1.CommandReadModels.extraPay.getById(this.entityId);
        return this._extraPay;
      });
      function getExtraPay() {
        return _getExtraPay.apply(this, arguments);
      }
      return getExtraPay;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var extraPay = yield this.getExtraPay();
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(extraPay.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var extraPay = yield this.getExtraPay();
        var e = new ExtraPayChanged_1.ExtraPayChanged(this.entityId, {}, extraPay.companyId);
        var hasChanged = false;
        if (this.data.mark != null && this.data.mark !== extraPay.mark) {
          e.data.mark = this.data.mark;
          hasChanged = true;
        }
        if (this.data.description != null && this.data.description !== extraPay.description) {
          e.data.description = this.data.description;
          hasChanged = true;
        }
        if (this.data.wageType != null && this.data.wageType !== extraPay.wageType) {
          e.data.wageType = this.data.wageType;
          hasChanged = true;
        }
        if (this.data.foreignId != null && this.data.foreignId !== extraPay.foreignId) {
          e.data.foreignId = this.data.foreignId;
          hasChanged = true;
        }
        if (this.data.field != null && this.data.field !== extraPay.field) {
          e.data.field = this.data.field;
          hasChanged = true;
        }
        if (this.data.fieldType != null && this.data.fieldType !== extraPay.fieldType) {
          e.data.fieldType = this.data.fieldType;
          hasChanged = true;
        }
        if (this.data.onceADay != null && this.data.onceADay !== extraPay.onceADay) {
          e.data.onceADay = this.data.onceADay;
          hasChanged = true;
        }
        if (!hasChanged) return [];
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var extraPay = yield this.getExtraPay();
        var errors = [];
        var costcenters = yield CommandReadModels_1.CommandReadModels.extraPay.get({
          filter: `deleted ne true and companyId eq '${extraPay.companyId}'`
        });
        if (key == null || key === 'mark') {
          if (this.data.mark == null || this.data.mark.length === 0) {
            errors.push({
              message: 'Das Kennzeichen wird benötigt',
              messageCode: 'companyExtraPayMarkRequired'
            });
          }
          if (this.data.mark != null) {
            if (this.data.mark.length > 20) {
              errors.push({
                message: 'Das Kennzeichen ist zu lang, maximal 20 Zeichen',
                messageCode: 'companyExtraPayMarkToLong'
              });
            }
            if (costcenters.find(function (element) {
              return _this2.data.mark === element.mark && _this2.entityId !== element.id;
            })) {
              errors.push({
                message: 'Eine Kostenstelle mit dem Kennzeichen existiert bereits',
                messageCode: 'companyExtraPayAlreadyExists'
              });
            }
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeExtraPay = ChangeExtraPay;