"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateExtraPay = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayCreated_1 = require("../../../events/company/extrapay/ExtraPayCreated");
var CreateExtraPay = function (_CommandRoot_1$Comman) {
  function CreateExtraPay(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateExtraPay);
    _this = _callSuper(this, CreateExtraPay, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateExtraPay';
    return _this;
  }
  (0, _inherits2.default)(CreateExtraPay, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateExtraPay, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ExtraPayCreated_1.ExtraPayCreated(this.entityId, {
          field: this.data.field,
          fieldType: this.data.fieldType,
          onceADay: this.data.onceADay,
          wageType: this.data.wageType,
          mark: this.data.mark,
          description: this.data.description,
          companyId: this.data.companyId,
          foreignId: this.data.foreignId
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var extraPays = yield CommandReadModels_1.CommandReadModels.extraPay.get({
          filter: `deleted ne true and companyId eq '${this.data.companyId}'`
        });
        if (key == null || key === 'mark') {
          if (this.data.mark == null || this.data.mark.length === 0) {
            throw {
              message: 'Das Kennzeichen wird benötigt',
              messageCode: 'companyExtraPayMarkRequired'
            };
          }
          if (this.data.mark.length > 20) {
            throw {
              message: 'Das Kennzeichen ist zu lang, maximal 20 Zeichen',
              messageCode: 'companyExtraPayMarkToLong'
            };
          }
          if (extraPays.find(function (element) {
            return _this2.data.mark === element.mark;
          })) {
            throw {
              message: 'Eine Kostenstelle mit dem Kennzeichen existiert bereits',
              messageCode: 'companyExtraPayAlreadyExists'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateExtraPay = CreateExtraPay;