"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCostCenter = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var CostCenterChanged_1 = require("../../../events/company/costcenter/CostCenterChanged");
var ChangeCostCenter = function (_CommandRoot_1$Comman) {
  function ChangeCostCenter(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeCostCenter);
    _this = _callSuper(this, ChangeCostCenter, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCostCenter';
    return _this;
  }
  (0, _inherits2.default)(ChangeCostCenter, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeCostCenter, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.costCenter = yield CommandReadModels_1.CommandReadModels.costCenter.getById(this.entityId);
        if (userId === 'all') return true;
        if (userId.startsWith('company') && userId.slice(7) === this.costCenter.companyId) return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.costCenter.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var e = new CostCenterChanged_1.CostCenterChanged(this.entityId, {}, this.costCenter.companyId);
        var hasChanged = false;
        if (this.data.mark != null && this.data.mark !== this.costCenter.mark) {
          e.data.mark = this.data.mark;
          hasChanged = true;
        }
        if (this.data.description != null && this.data.description !== this.costCenter.description) {
          e.data.description = this.data.description;
          hasChanged = true;
        }
        if (this.data.costType != null && this.data.costType !== this.costCenter.costType) {
          e.data.costType = this.data.costType;
          hasChanged = true;
        }
        if (this.data.foreignId != null && this.data.foreignId !== this.costCenter.foreignId) {
          e.data.foreignId = this.data.foreignId;
          hasChanged = true;
        }
        if (this.data.active != null && this.data.active !== this.costCenter.active) {
          e.data.active = this.data.active;
          hasChanged = true;
        }
        if (!hasChanged) return [];
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var errors = [];
        var costcenters = yield CommandReadModels_1.CommandReadModels.costCenter.get({
          filter: `deleted ne true and companyId eq '${this.costCenter.companyId}'`
        });
        if (key == null || key === 'mark') {
          if (this.data.mark == null || this.data.mark.length === 0) {
            errors.push({
              message: 'Das Kennzeichen wird benötigt',
              messageCode: 'companyCostCenterMarkRequired'
            });
          }
          if (this.data.mark != null) {
            if (this.data.mark.length > 20) {
              errors.push({
                message: 'Das Kennzeichen ist zu lang, maximal 20 Zeichen',
                messageCode: 'companyCostCenterMarkToLong'
              });
            }
            if (costcenters.find(function (element) {
              return _this2.data.mark === element.mark && _this2.entityId !== element.id;
            })) {
              errors.push({
                message: 'Eine Kostenstelle mit dem Kennzeichen existiert bereits',
                messageCode: 'companyCostCenterAlreadyExists'
              });
            }
          }
        }
        if (key == null || key === 'costType') {
          var rightType = this.data.costType === 'costUnit' || this.data.costType === 'costCenter';
          if (!rightType) {
            errors.push({
              message: 'costType must be costCenter or costUnit',
              messageCode: 'costTypeTypeError'
            });
          }
        }
        if (this.costCenter.costType === 'costUnit' && this.data.costType === 'costCenter' && this.costCenter.projectIds != null && this.costCenter.projectIds.length > 0) {
          errors.push({
            message: 'Der Kostenträger kann nicht in eine Kostenstelle geändert solange noch Projekte zugeordnet sind',
            messageCode: 'companyCostCenterCantChangeFromCostUnitIfProjectsAdded'
          });
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeCostCenter = ChangeCostCenter;